import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Products from "../components/Products";
import SEO from "../components/SEO";

const AndetPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(filter: { context: { productType: { eq: "andet" } } }) {
        edges {
          node {
            context {
              productTitle
              productImage
              productPath
              productPrice
              productOldprice
              productLink
              productId
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Find udstyr, tøj, kosttilskud og andet på Billigform"
        description="Spar en masse penge og find de bedste priser på træningsudstyr til hjemmet."
      />
      <Products data={data} />
    </>
  );
};

export default AndetPage;
